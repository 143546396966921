import {Theme, createStyles} from "@material-ui/core";
export const Styles =(theme: Theme) => createStyles({
    phoneNumberWithCountryCode: {
        width: "100%"
    },
    messageBlockMain:{
        maxWidth: "800px",
        margin: "auto",
        width: "100%"
    },
    registrationBlockMain:{
        maxWidth: "600px",
        margin: "auto",
        width: "100%"
    },
    inputTags:{
        "fontSize":"14px",
        "fontFamily": "Montserrat",
    },
    peraContainer:{

    },
    actveButton:{
        "borderRadius": "24px",
        "background": "#10BFB8",
        "minWidth": "240px",
        "height": "48px",
        "color": "#FFF",
        "textAlign": "center",
        "fontFamily": "Montserrat",
        "fontSize": "18px",
        "fontStyle": "normal",
        "fontWeight": 700,
        "lineHeight": "24px",
        "margin": "auto",
        "textTransform": "capitalize",
        "transition": "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        "&:hover":{
            "background": "#10BFB8",
            "boxShadow": "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"
        }
    },
    phoneInput:{
        width: "51%"
    },
    submitFormBtn:{
        "borderRadius": "24px",
        "background": "#8C8C8C",
        "minWidth": "240px",
        "height": "48px",
        "color": "#FFF",
        "textAlign": "center",
        "fontFamily": "Montserrat",
        "fontSize": "18px",
        "fontStyle": "normal",
        "fontWeight": 700,
        "lineHeight": "24px",
        "textTransform": "capitalize",
        "margin": "auto",
        "cursor": "context-menu",
        "&:hover":{
            "background": "#8C8C8C",
            "pointerEvents": "none"
        },
        "& *":{
            "cursor": "context-menu",
        }
    },
    downArrowBtn:{
        "height": "48px",
        "width": "15%",
        "backgroundColor": "white",
        "display": "flex",
        "boxSizing": "border-box",
        "alignItems": "center",
        "justifyContent": "center",
        "border": "1px solid #8C8C8C",
        "borderRadius": "10px",
        "& img":{
            width: "14px"
        }
    },
    signUpBtn:{
        "minWidth": "240px",
        "height": "48px",
        "borderRadius": "30px",
        "border": "2px solid #10BFB8",
        "color": "#10BFB8",
        "textAlign": "center",
        "fontFamily": "Montserrat",
        "fontSize": "18px",
        "fontStyle": "normal",
        "fontWeight": 700,
        "lineHeight": "24px",
        "margin": "auto",
        "textTransform": "capitalize",
        "transition": "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        "&:hover":{
            "boxShadow": "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"
        }
    },
    timerBox:{
        display: "flex",
        justifyContent:"space-between",
        alignItems:"center"
    },
    timertext:{
        fontSize:"12px",
        padding:"6px 8px",
        fontFamily:"Montserrat",
        fontWeight:500
    },
    footerLink:{
        display: "flex",
        justifyContent: "end"
    },
    formAction:{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginTop: "16px"
    },
    moreAction:{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "center",
        "& p":{
            "color": "#AAA",
            "fontFamily": "Montserrat",
            "fontSize": "14px",
            "fontStyle": "normal",
            "fontWeight": 500,
            "lineHeight": "24px",
            "textAlign": "center"
        }
    },
    link1:{
        "color": "#AAA",
        "fontFamily": "Montserrat",
        "fontSize": "12px",
        "fontStyle": "normal",
        "fontWeight": 500,
        "lineHeight": "24px",
        "textDecorationLine": "underline",
        "textTransform": "inherit",
        "cursor": "pointer",
        "&:hover":{
            "textDecorationLine": "underline",
            "backgroundColor": "transparent",
            "color": "#8c8c8c"
        }
    },
    errormsg :{ 
        borderRadius: "12px",
        background: "rgba(255, 117, 117, 0.30)",
        width: "100% !important",
        boxSizing: "border-box",
        marginBottom: "5px"
    },
    errormsgText: {
        color: "#FF7575",
        textAlign: "center",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "24px",
        fontFamily: "Montserrat"
    },
    searchList:{
        "padding": "10px 0px",
        "display": "flex",
        "justifyContent": "space-between",
        "fontFamily": "Montserrat",
        gap: "10px",
        " & img":{
            borderRadius: "6px",
            border: "1px solid #cbcbcb",
            width: "40px"
        }
    },
    resultList:{
        "width": "100%",
        "backgroundColor": "white",
        "display": "flex",
        "flexDirection": "column",
        "marginTop": "1rem",
        "maxHeight": "390px",
        "overflowY": "auto",
        "padding": "10px",
        "boxSizing": "border-box"
    },
    formLabel: {
        "color": "#262626",
        "fontFamily": "Montserrat",
        "fontSize": "16px",
        "fontStyle": "normal",
        "fontWeight": 500,
        "lineHeight": "24px",
        "marginBottom": "12px",
        "display": "block"
    },
    codeActive:{
        "fontFamily": "Montserrat",
        "fontWeight": 500,
        "fontSize": "18px",
        "lineHeight": "24px",
        "color": "#000"
    },
    codeInActive:{
        "fontFamily": "Montserrat",
        "fontWeight": 300,
        "fontSize": "16px",
        "color": "#8C8C8C",
        "lineHeight": "20px",
        "letterSpacing": "3.2px",
        "margin": "auto"
    },
    formInput:{
        width: "100%",
        "& input": {
            "color": "#000",
            "fontFamily": "Montserrat",
            "fontSize": "18px",
            "fontStyle": "normal",
            "fontWeight": 500,
            "lineHeight": "24px",
            "letterSpacing": "3.2px",
            '&::placeholder': {
                color: "#8C8C8C",
                "fontWeight": 300,
                "fontSize": "16px",
                "lineHeight": "22px",
            },
            '&::-ms-input-placeholder': {
                color: "#8C8C8C",
                "fontWeight": 300,
                "fontSize": "16px",
                "lineHeight": "22px",
            }
        }
    },
    formOtpInput:{
        width: "100%",
        "& input": {
            "color": "#262626",
            "fontFamily": "Montserrat",
            "fontSize": "18px",
            "fontStyle": "normal",
            "fontWeight": 500,
            "lineHeight": "24px",
            "letterSpacing": "5.4px",
            "padding": "12px 24px",
            "borderRadius": "12px",
            "border": "1px solid #8C8C8C",
            '&::placeholder': {
                color: "#8C8C8C",
                "fontWeight": 500,
                "fontSize": "16px",
                "lineHeight": "22px",
                "letterSpacing": "0px",
            },
            '&::-ms-input-placeholder': {
                color: "#8C8C8C",
                "fontWeight": 500,
                "fontSize": "16px",
                "lineHeight": "22px",
                "letterSpacing": "0px",
            }
        }
    },
    successLabel:{
        display: "flex",
        gap: "20px",
        width: "100%",
        justifyContent: "center",
        padding: "0px 0px 45px",
        alignItems: "center",
        "& img":{

        },
        "& span":{
            lineHeight: "60px",
            color: "#000",
            fontFamily: "Montserrat",
            fontSize: "30px",
            fontStyle: "normal",
            fontWeight: "700"
        }
    },
    containerForm: {
        margin: "0 auto",
        marginTop: "100px",
        marginBottom: "150px",
        height: "relative",
        border: "2px solid transparent",
        borderRadius: "20px",
       
    },
    ForgotPassword_WhiteBox: {
        backgroundColor: '#fff',
        borderRadius: '18px',
        padding: '16px 108px',
        border: "1px solid #000",
        background: "#FFF"
    },
      subpera:{
        "color": "#000",
        "textAlign": "center",
        "fontFamily": "Montserrat",
        "fontSize": "14px",
        "fontStyle": "normal",
        "fontWeight": 400,
        marginBottom: "14px",
        "lineHeight": "18px"
      },
      mainHeading_wrapper:{
        color: "#5412FC",
        textAlign: "center",
        fontFamily: "Montserrat",
        fontSize: "30px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "36px",
        marginBottom: "14px",
      },
    
      button: {
        marginTop: 16,
        justifyContent: "center",
        alignItems: "center",
        width: "80%",
        zIndex: -1,
      },
    
      clearButton: {
        color: "#10BFB8",
        borderRadius: "24px",
        height: "48px",
        width: "200px",
        justifyContent: "center",
        border: "2px solid #10BFB8",
        fontWeight: "bold",
        fontSize: "20px",
        fontFamily: "Montserrat",
        fontStyle: "normal",
        textTransform: "inherit"
      },
    
      RowElementInput: {
        padding: "12px",
        borderRadius: "12px",
        border: "1px solid #8C8C8C",
        width: "100%",
        height: "48px",
       
      },
      RowElement: {
        width: "100%",
        justifyContent: "space-between",
        marginBottom: "15px",
        display: "flex",
        flexDirection: "column",
        margin: "0px",
       
      },
      successMsg:{
        borderRadius: "12px",
        background: "rgba(121, 255, 117, 0.3)",
        width: "100%",
        padding: "5px",
        marginBottom: "24px",
        color: "#4AD560",
        textAlign: "center",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "24px",
        fontFamily: "Montserrat"
        
      },
      countryCodeDownTriangleAndPhoneNumber: {
        marginTop: "0px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        gap: "10px",
        width: "100%"
      },
      countryCodeWithDownTriangle: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: "10px"
      },
      flagWithCode: {
        width: "116px",
        height: "48px",
        padding: "12px",
        borderRadius: "12px",
        boxSizing: "border-box",
        border: "1px solid #8C8C8C",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        textAlign: "center",
        backgroundColor: "white",
        gap: "2px",
        " & img":{
            borderRadius: "4px",
            width: "40px",
            height: "26px"
        }
      },
      thridRowInputElement: {
        width: "100%",
        height: "48px",
        padding: "12px",
        borderRadius: "12px",
        border: "1px solid #8C8C8C",
        fontSize: "14px",
        boxSizing: "border-box",
        fontFamily: "Montserrat,sans-serif"
      },
      modal: {
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px"
      },
      paper: {
        backgroundColor: "white",
        height: "450px",
        borderRadius: "10px",
        padding: "30px 30px",
        margin: "auto"
      },
      '@media (max-width: 767px)': {
        ForgotPassword_WhiteBox: {
          padding: '37px 40px',
        },
        formAction:{
            gap: "26px"
        },
        mainHeading_wrapper:{
            marginBottom: "18px",
            fontSize: "16px"
        },
        subpera:{
            fontSize: "9px !important",
            lineHeight: "13px",
            marginBottom: "10px"
        },
        formLabel:{
            fontSize: "10px !important",
            lineHeight: "20px"
        },
        successMsg:{
            fontSize: "10px !important",
            lineHeight: "20px"
        },
        phoneInput:{
            width: "auto"
        },
        flagWithCode:{
            fontSize: "10px",
            width: "68.69px",
            lineHeight: "32px",
            borderRadius: "8px",
            padding: "5px 3px",
            height: "32px",
            "& img": {
                width: "26px",
                height: "16px"
            }
        },
        downArrowBtn:{
            height: "32px",
            width: "41px",
            borderRadius: "8px",
        },
        codeActive:{
            fontSize: "10px",
            lineHeight: "20px",
            letterSpacing: "1px"
        },
        codeInActive:{
            fontSize: "10px",
            lineHeight: "20px",
            letterSpacing: "1px"
        },
        formInput:{
            width: "100%",
            "& input": {
                fontSize: "10px",
                lineHeight: "20px",
                borderRadius: "8px",
                height: "32px",
                padding: "5px 10px",
                '&::placeholder': {
                    fontSize: "10px",
                    lineHeight: "20px",
                }
            }
        },
        formOtpInput:{
            "& input": {
                fontSize: "10px",
                lineHeight: "20px",
                borderRadius: "8px",
                height: "32px",
                padding: "5px 10px",
                '&::placeholder': {
                    "fontSize": "10px",
                    "lineHeight": "20px"
                },
                '&::-ms-input-placeholder': {
                    "fontSize": "10px",
                    "lineHeight": "20px"
                }
            }
        },
        link1:{
            fontSize: "10px !important",
            lineHeight: "20px",
        },
        submitFormBtn:{
            fontSize: "12px",
            padding: "6px 27px",
            minWidth: "106px",
            height: "25.8px"
        },
        actveButton:{
            fontSize: "12px",
            padding: "6px 27px",
            minWidth: "106px",
            height: "25.8px"
        },
        signUpBtn:{
            fontSize: "12px",
            padding: "6px 13px",
            minWidth: "106px",
            height: "25.8px",
            "& img":{
                width: "20px"
            }
        },
        errormsgText:{
            fontSize: "10px !important",
            lineHeight: "20px",
        },
        moreAction:{
            "& p":{
                fontSize: "10px !important",
                lineHeight: "20px",
            }
        },
        successLabel:{
            "& span":{
                fontSize: "24px"    
            },
            "& img":{
                width: "39px"
            }
        }
      },
      '@media (max-width: 460px)': {
        phoneInput:{
            width: "51%"
        },
      }
})